<div class="navbar-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Party Games Online!</h1>
            </div>
        </div>
    </div>
</div>

<div class="navbar-desktop" id="navbar-desktop">
    <div class="container-fluid">
        <mat-toolbar>
            <mat-toolbar-row>
                <a routerLink="/"><img src="/assets/img/logos/logo.jpg"></a>
                <!-- <a routerLink="/"><h1>{{ appTitle }}</h1></a> -->
                <span class="spacer"></span>
                <!-- <button routerLink="/" >Home</button>
                <button routerLink="/">Portfolio</button>
                <button routerLink="/">Services</button> -->
                <a class="button" target="_blank" href="https://www.paypal.com/donate?hosted_button_id=ZRD6QY6N6ETL6">Donate</a>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>
