export const games = [
    {
        id: '21-magic',
        name: '21 Magic',
        category: 'party',
        trending: true,
        description: 'Pick a card! Any card! And we\'ll guess what\'s on your mind!',
        url: 'https://www.21magic.thegreenedgellc.com/',
    },
    // {
    //     id: 'cyber-chase',
    //     name: 'Cyber Chase',
    //     category: 'learning',
    //     trending: false,
    //     description: 'A kids game with over 150+ questions.',
    //     url: 'https://www.thegreenedgellc.com/',
    // },
    {
        id: 'danger-word',
        name: 'Danger word online',
        category: 'party',
        trending: true,
        description: 'Play the popular danger word game with friends. Also played on Ellen\'s Game of Games!',
        url: 'https://www.dangerword.thegreenedgellc.com/',
    },
    {
        id: 'emoji-game',
        name: 'The Emoji Game',
        category: 'party',
        trending: false,
        description: 'A kids game with over 150+ questions.',
        url: 'https://www.emojigame.thegreenedgellc.com/',
    },
    {
        id: 'guess-that-flag',
        name: 'Guess That Flag',
        category: 'party',
        trending: false,
        description: 'Put your geography skills to the test with the ultimate flag guessing game!',
        url: 'https://www.guessthatflag.thegreenedgellc.com/',
    },
    // {
    //     id: 'elmos-world',
    //     name: 'Elmo\s World',
    //     category: 'kids',
    //     trending: false,
    //     description: 'A kids game with over 150+ questions.',
    //     url: 'https://www.thegreenedgellc.com/',
    // },
    // {
    //     id: 'mafia',
    //     name: 'Mafia',
    //     category: 'party',
    //     trending: false,
    //     description: 'Based on the popular game Mafia or Werewolf where citizens work to take down Mafia amoung us.',
    //     url: 'https://www.thegreenedgellc.com/',
    // },
    // {
    //     id: 'not-so-newlywed',
    //     name: 'Not So Newlywed',
    //     category: 'party',
    //     trending: false,
    //     description: 'A couples game with over 150+ questions for you and your significant other.',
    //     url: 'https://www.thegreenedgellc.com/',
    // },
];
