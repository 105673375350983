import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { games } from '../shared/games';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  games = games;

  id: any;
  url: any;
  gameURL: any;
  loading: boolean | undefined;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.loadGame();
  }

  loadGame(){
    this.id = this.router.url.replace('/game/', '');
    if (this.id !== '') {
      this.url = this.games.find((person: { id: any; }) => person.id === this.id)?.url;
      this.gameURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      // console.log(this.url, this.gameURL);
    } else {
      this.router.navigate(['/four-zero-four']);
    }
  }

  ngAfterViewInit(): void {
    this.loading = true;
  }

}
