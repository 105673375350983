import { NgModule } from '@angular/core';

// ANIMATIONS
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// WEBSITE DIALOG BOX
import { MatDialogModule } from '@angular/material/dialog';

// MATERIAL
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDividerModule,
        MatProgressSpinnerModule
    ],
    exports: [
        BrowserAnimationsModule,
        MatDialogModule,
        MatToolbarModule,
        MatDividerModule,
        MatProgressSpinnerModule
    ],
})
export class CustomMaterialModule { }
