import { Component, OnInit } from '@angular/core';
import { games } from '../shared/games';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // NAVIGATION
  sideNavStatus: boolean | undefined;

  // GAMES
  games = games;
  category = 'all';
  categoryTotal: any;

  gameId: any;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sideNavStatus = false;
    // console.log(this.sideNavStatus);
  }

  navigation(event: any): void {
    this.category = event;
    this.router.navigate(['/#games']);
  }

  toggleMobileNav(): void {
    if (this.sideNavStatus === false) {
      this.sideNavStatus = true;
      console.log(this.sideNavStatus);
    } else {
      this.sideNavStatus = false;
      console.log(this.sideNavStatus);
    }
  }

  playGame(event: any): void {
    this.gameId = this.games[event].id;
    this.router.navigate(['/game/' + this.gameId]);
    console.log(this.gameId);
  }
}
