<app-nav></app-nav>

<div class="navbar-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Party Games Online!</h1>
            </div>
        </div>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="left">
                <a routerLink="/"><img src="/assets/img/logos/logo.jpg"></a>
            </div>
            <div class="center">
                <!-- <p>{{ appTitle }}</p> -->
            </div>
            <div class="right">
                <button (click)="toggleMobileNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="sideNavStatus === true" id="sideNav">
    <div class="container">
        <div class="row">
            <button class="close-button" (click)="toggleMobileNav()"><i class="material-icons">close</i></button>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <img src="/assets/img/logos/logo.jpg">
                <button [ngClass]="{'active': category === 'all'}" (click)="navigation('all')" (click)="toggleMobileNav()">All</button>
                <button [ngClass]="{'active': category === 'party'}" (click)="navigation('party')" (click)="toggleMobileNav()" >Party Games</button>
                <button [ngClass]="{'active': category === 'kids'}" (click)="navigation('kids')" (click)="toggleMobileNav()">Kids Games</button>
                <button [ngClass]="{'active': category === 'learning'}" (click)="navigation('learning')" (click)="toggleMobileNav()">Learning Games</button>
                <a target="_blank" (click)="toggleMobileNav()" href="https://www.paypal.com/donate?hosted_button_id=ZRD6QY6N6ETL6">Donate</a>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-sidebar">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button [ngClass]="{'active': category === 'all'}" (click)="navigation('all')"><i class="fas fa-fire"></i>All</button>
                <button [ngClass]="{'active': category === 'party'}" (click)="navigation('party')"><i class="fas fa-dice"></i>Party Games</button>
                <button [ngClass]="{'active': category === 'kids'}" (click)="navigation('kids')"><i class="fas fa-child"></i>Kid Games</button>
                <button [ngClass]="{'active': category === 'learning'}" (click)="navigation('learning')"><i class="fas fa-apple-alt"></i>Learning Games</button>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-content">

    <div class="header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                    <img src="/assets/img/photos/fallguys.png" alt="fall guys">
                </div>
                <div class="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12">
                    <h1>Fall Guys!</h1>
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                    <button (click)="playGame('fall-guys')">Play Now</button>
                </div>
            </div>
        </div>
    </div>

    <div class="games" id="games">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div *ngFor="let game of games; index as gameId">
                        <!-- ALL GAMES -->
                        <div *ngIf="this.category === 'all'">
                            <div class="widget">
                                <img src="/assets/img/games/{{ game.id }}.jpg " alt="">
                                <h1>{{ game.name }}</h1>
                                <p>{{ game.description }}</p>
                                <button (click)="playGame(gameId)">Play Game</button>
                            </div>
                        </div>
                        <!-- GAMES BY CATEGORY -->
                        <div *ngIf="game.category === this.category">
                            <div class="widget">
                                <img src="/assets/img/games/{{ game.id }}.jpg " alt="">
                                <h1>{{ game.name }}</h1>
                                <p>{{ game.description }}</p>
                                <button (click)="playGame(gameId)">Play Game</button>
                            </div>
                        </div>
                    </div>
                    <!-- <p *ngIf="this.category !== 'all'">There are no current games in this category.</p> -->
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>