import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Custom Material Module
import { CustomMaterialModule } from './shared/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FourZeroFourComponent } from './four-zero-four/four-zero-four.component';
import { DialogPromoComponent } from './dialog-promo/dialog-promo.component';
import { GameComponent } from './game/game.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    FourZeroFourComponent,
    DialogPromoComponent,
    GameComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomMaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
